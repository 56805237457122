import * as Sentry from "@sentry/nuxt";

Sentry.init({
    dsn: "https://2c31a0929ddc2925c03b8f91d24040e0@o4504170234183680.ingest.us.sentry.io/4508058538868736",
    // dsn: "https://06bdbe7f9682f1ecdb9f2486ebd97b52@o4507661325565952.ingest.de.sentry.io/4507661446152272",
    integrations: [
        Sentry.replayIntegration()
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracing: true,
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    beforeSend(event) {
        // Ignore errors from Cookiebot
        if (event.exception && event.exception.values) {
            const isCookiebotError = event.exception.values.some((exception) =>
                exception.value.includes('Cookiebot')
            );
            if (isCookiebotError) {
                return null; // Ignore errors from Cookiebot
            }
        }
        return event; // Send all other errors to Sentry
    },
});
